import React, { Component, Children, useEffect } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import '../styles/lightbox_override.css';
import { GatsbyImage } from 'gatsby-plugin-image';

import DefaultLayout from '../components/layout'
import SEO from '../components/seo'

import 'katex/dist/katex.min.css'

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
    this.buttonFunction = this.buttonFunction.bind(this);
  }
  buttonFunction(event){
    if (event.key === "ArrowLeft") {
      this.props.pageContext.nextitem && navigate(this.props.pageContext.nextitem.fields.slug);
    } else if (event.key === "ArrowRight") {
      this.props.pageContext.previousitem && navigate(this.props.pageContext.previousitem.fields.slug);
    } else if (event.key === "ArrowUp") {
      navigate("/");
    }
  }
  componentDidMount(){
    document.addEventListener("keydown", this.buttonFunction, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.buttonFunction, false);
  }
  render() {
    const images = this.props.data.pagedata.frontmatter.galleryImages.map((image) => image);
    const { previousitem, nextitem } = this.props.pageContext
    const post = this.props.data.pagedata
    const chapters = this.props.data.chapters.edges
    const issubtitle = post.frontmatter.subtitle
    const comma = post.frontmatter.comma
    const { photoIndex, isOpen } = this.state;
    let fulltitle = ""
    if (issubtitle && comma) {
      fulltitle = post.frontmatter.title + "," + "<br />" + issubtitle
    } else if (issubtitle) {
      fulltitle = post.frontmatter.title + "<br />" + issubtitle
    } else {
      fulltitle = post.frontmatter.title
    }
    return (
      <DefaultLayout>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <div className="clearfix post-content-box">
          <div className="clearfix inline-headers">
            <h4 className="h4smaller"><a href="/authors/">Максим&nbsp;Гурбатов&nbsp;<em>и</em> Анна&nbsp;Чайковская</a></h4>
            <h3><a href="/">Книга Букв</a></h3>
            <h4><a href="/contents/"><em>самый первый литерный проект</em></a></h4>
          </div>
          <div className="clearfix chapter-headers">
            <div className="chapter-nav">
            <a className={nextitem ? "ch-nav-arrow left" : "ch-nav-arrow left nolink"} href={nextitem && nextitem.fields.slug}></a>
              <h2>Глава {post.frontmatter.chapter}. {post.frontmatter.chapter_name} </h2>
              <a className={previousitem ? "ch-nav-arrow right" : "ch-nav-arrow right nolink"} href={previousitem && previousitem.fields.slug}></a>
            </div>
            <ul className="chapter-list">
              {chapters.map(({ node }) => {
                const nodename = node.frontmatter.title
                const current = nodename === post.frontmatter.title
                if (current) {
                  return <li key={nodename} dangerouslySetInnerHTML={{ __html: nodename }}></li>
                } else {
                  return <li key={nodename}><a href={node.fields.slug} dangerouslySetInnerHTML={{ __html: nodename }} /></li>
                }
              })}
            </ul>
          </div>
          <article className="article-page">
            <div className="page-content row">
              {post.frontmatter.img && (
                <div className="page-cover-image column">
                  <figure onClick={() => this.setState({ isOpen: true })}>
                    <GatsbyImage
                      image={
                        post.frontmatter.img.childImageSharp.gatsbyImageData
                      }
                      className="page-image"
                      key={
                        post.frontmatter.img.childImageSharp.gatsbyImageData.src
                      }
                      alt={post.frontmatter.title}
                    />
                  </figure>
                  <div className="artlabel">
                  <h5 dangerouslySetInnerHTML={{ __html: post.frontmatter.title }} />
                  <em dangerouslySetInnerHTML={{ __html: post.frontmatter.description }} /><br />
                  <span className="forsale" dangerouslySetInnerHTML={{ __html: post.frontmatter.for_sale }} />
                  </div>
                </div>
              )}
              <div className="wrap-content column">
                <header className="header-page">
                  <h1 className="page-title" dangerouslySetInnerHTML={{ __html: fulltitle }} />
                </header>
                <div className="words incontent" dangerouslySetInnerHTML={{ __html: post.html }} />
              </div>
            </div>
          </article>
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length,
                })
              }
            />
          )}
        </div>
      </DefaultLayout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $chapter: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    pagedata: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        comma
        description
        chapter
        chapter_name
        for_sale
        subtitle
        date(formatString: "YYYY, MMM DD")
        img {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, formats: [JPG])
          }
        }
        galleryImages
      }
    }
    chapters: allMarkdownRemark(
      filter: {frontmatter: {chapter: {eq: $chapter}}}
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY, MMM DD")
            title
            chapter
          }
        }
        next {
          fields {
            slug
          }
        }
        previous {
          fields {
            slug
          }
        }
      }
    }
  }
`

